export const SIGN_IN = "SIGN_IN";

export const REQUEST_USERDATA = "REQUEST_USERDATA";

export const REQUEST_USERDATA_ONLOAD = "REQUEST_USERDATA_ONLOAD";

export const LOGIN_ISLOGGED = "LOGIN_ISLOGGED";

export const REQUEST_USER_INFO_UPDATE = "REQUEST_USER_INFO_UPDATE";

export const REQUEST_PROFILE_UPDATE = "REQUEST_PROFILE_UPDATE";

export const REQUEST_BILLBOARD_UPDATE = "REQUEST_BILLBOARD_UPDATE";

export const REQUEST_BILLBOARD_UPDATE2 = "REQUEST_BILLBOARD_UPDATE2";

export const REQUEST_MEMBER = "REQUEST_MEMBER";

export const REQUEST_MEMBERDATA = "REQUEST_MEMBERDATA";

export const REQUEST_USER_REG_UPDATE = "REQUEST_USER_REG_UPDATE";

export const NEXT_FEED_DATA = "NEXT_FEED_DATA";
